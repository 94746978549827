
import { defineComponent, reactive, ref } from "vue";
import { message } from "ant-design-vue";
import { changePwdRequest } from "@/service/common";
import { useRouter } from "vue-router";
import store from "@/store";
export default defineComponent({
  setup() {
    const router = useRouter();
    const formEle = ref();
    const formState = reactive({
      oldPassword: "",
      newPassword: "",
      newPasswordToo: "",
    });
    const rules = {
      oldPassword: [
        {
          required: true,
          trigger: "blur",
          message: "请输入原密码",
        },
      ],
      newPassword: [
        {
          required: true,
          trigger: "blur",
          message: "请输入新密码",
        },
      ],
      newPasswordToo: [
        {
          required: true,
          trigger: "blur",
          message: "请输入新密码",
        },
      ],
    };
    const btnLoading = ref(false);
    const onSubmit = () => {
      console.log(formState);
      formEle.value.validate().then(() => {
        if (formState.newPassword !== formState.newPasswordToo) {
          message.error("两次新密码输入不一致，请确认！");
          return;
        }
        btnLoading.value = true;
        changePwdRequest({
          newPassword: formState.newPassword,
          oldPassword: formState.oldPassword,
        })
          .then(async (res) => {
            if (res.code === 200) {
              await store.dispatch("ClearToken");
              message.success("修改成功，请重新登录");
              setTimeout(() => {
                router.replace({ name: "Login" });
              }, 1000);
            }
          })
          .finally(() => (btnLoading.value = false));
      });
    };
    return {
      formState,
      btnLoading,
      onSubmit,
      rules,
      formEle,
    };
  },
});
